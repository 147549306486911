/* ---- Imports ---- */

import { createRouter, createWebHistory } from 'vue-router'

/* ---- Routes ---- */

import admin from './admin'

/* ---- System ---- */

const router = createRouter({
  history: createWebHistory(),
  routes: [...admin]
})

router.afterEach((to) => {
  document.title = to.meta.title
})

export default router
