export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../components/admin/login/Login.vue'),
    meta: {
      logged: false,
      title: 'Login Admin',
      layout: 'default-layout'
    }
  },
  {
    path: '/painel',
    name: 'painel',
    component: () => import('../components/admin/painel/Painel.vue'),
    meta: {
      logged: false,
      title: 'Painel Admin',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/admin/erro/Erro.vue'),
    meta: {
      logged: false,
      title: 'Essa página não existe...',
      layout: 'default-layout'
    }
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../components/admin/perfil/Perfil.vue'),
    meta: {
      logged: true,
      title: 'Perfil | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/rifas',
    name: 'rifas',
    component: () => import('../components/admin/rifas/Rifas.vue'),
    meta: {
      logged: true,
      title: 'Rifas | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/compradores',
    name: 'compradores',
    component: () => import('../components/admin/compradores/Compradores.vue'),
    meta: {
      logged: true,
      title: 'Compradores | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/ganhadores',
    name: 'ganhadores',
    component: () => import('../components/admin/ganhadores/Ganhadores.vue'),
    meta: {
      logged: true,
      title: 'Ganhadores | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/bilhetes',
    name: 'bilhetes',
    component: () => import('../components/admin/bilhetes/Bilhetes.vue'),
    meta: {
      logged: true,
      title: 'Bilhetes | Admin Oficina das Rifas',
      layout: 'navbar-layout'
    }
  }
]
